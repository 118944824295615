<template>
  <div class="success-cont">
    <div class="cont-in">
			<img class="img" src="@/assets/image/icon-4.png" alt="">
      <div class="text">
        <p>系统已经接收到您的申请</p>
        <p>感谢您的配合！！</p>
        <p>请耐心等待我们的服务人员与您联系</p>
      </div>
      <router-link to="/home" replace>
        <div class="form-sub">确定</div>
      </router-link>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.success-cont{
  min-height: 100vh;
  display: flex;
  align-items:center;
	background: #fff;
}
.img{
	display: block;
	width: 284px;
  height: 274px;
  margin: 0 auto;
}
.cont-in{
  margin: 0 auto;
}
.text{
  padding: 38px 0;
  text-align: center;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
}
.form-sub{
  width: 330px;
  height: 90px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 90px;
  color: #fff;
  margin: 0 auto;
}
</style>
